<template>
  <div style="position: relative">
    <input class="category-checkbox__input" :value="item.title" :id="item.id" type="checkbox">
    <label class="category-checkbox__label fs-16 fw-700" :for="item.id">
      <div class="category-checkbox__label__inner">
        <Icon :icon="item.icon" background="blue-dark" class="mr-s"></Icon>
        <slot></slot>
      </div>
      <span class="fs-14 category-checkbox__label__meta" v-if="item.meta">{{ item.meta }}</span>
    </label>
  </div>
</template>

<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  item = prop({
    type: Object,
    required: true,
    default: {
      id: 'dental_care',
      title: 'Dental Care',
      icon: 'eye',
      meta: ''
    }
  })
}

@Options({
  name: 'Category-checkbox',
  components: {
    Icon
  }
})

export default class CategoryCheckbox extends Vue.with(Props) {}
</script>

<style lang="scss" scoped>
  .category-checkbox__label {
    display: inline-block;
    border-radius: 20px;
    padding: 6px;
    padding-right: 15px;
    transition: background-color 0.2s ease;
    margin-bottom: 10px;

    &:hover {
      background-color: $blue-dark-10;
      cursor: pointer;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .category-checkbox__label__inner {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
  }

  .category-checkbox__input {
    position: absolute;
    opacity: 0;
  }

  .category-checkbox__label__meta {
    font-weight: 400;
    display: block;
    padding-left: 35px;
  }
</style>
