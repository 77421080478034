
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Separator from '@/02-molecules/Separator.vue'
import Icon from '@/01-atoms/Icon.vue'
import HelpText from '@/02-molecules/Help-text.vue'
import Tag from '@/01-atoms/Tag.vue'
import InquiryUpload from '@/02-molecules/Inquiry-upload.vue'
import MoreMenu from '@/02-molecules/More-menu.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { getInquiry, deleteInquiry } from '@/api/inquiries/inquiries-api'
import { inject } from 'vue'
import { ErrorHandlerKey, InquiryServiceKey } from '@/services/serviceTypes'
import { Image, Inquiry } from '@/services/inquiries/InquiryService'
import { getFileDownload } from '@/api/uploads/files-api'
import sanitizeDownloadTitle from '@/helpers/sanitize-download-title'

@Options({
  name: 'Inquiry',
  components: {
    IconButton,
    Separator,
    Icon,
    HelpText,
    Tag,
    InquiryUpload,
    MoreMenu,
    BackButton
  }
})

export default class InquiryOverview extends Vue {
  inquiryUploads: Array<Image> = []
  lightboxImages: Array<string> = []
  lightboxIsVisible = false
  lightboxToOpenIndex = 0

  confirmationContent = ''
  confirmationType = ''
  confirmationLightboxIsVisible = false

  showReUploadMessage = false

  menu = [
    //{
    //  label: 'inquiry.menu.add',
    //  icon: 'camera'
    //},
    {
      label: 'inquiry.menu.edit',
      icon: 'edit-2',
      action: 'edit'
    },
    {
      label: 'inquiry.menu.delete',
      icon: 'trash',
      class: 'red',
      action: 'delete'
    }
  ]

  menuAction (action: string) {
    switch (action) {
      case 'delete':
        this.deleteInquiry()
        break
      case 'edit':
        this.$router.push({ name: 'Edit inquiry', params: { id: this.inquiry.id } })
        break
      case 'download': {
        const hiddenElement = document.createElement('a')
        hiddenElement.setAttribute('download', sanitizeDownloadTitle(this.inquiry.title))
        hiddenElement.setAttribute('href', this.confirmationContent)
        hiddenElement.click()
        break
      }
    }
  }

  inquiryMenu () {
    let menu = []
    if (this.inquiry.status === 'pending') {
      menu = [
        //{
        //  label: 'inquiry.menu.add',
        //  icon: 'camera'
        //},
        {
          label: 'inquiry.menu.edit',
          icon: 'edit-2',
          action: 'edit'
        },
        {
          label: 'inquiry.menu.delete',
          icon: 'trash',
          class: 'red',
          action: 'delete'
        }
      ]
    } else {
      menu = [
        {
          label: 'inquiry.menu.save-as-pdf',
          icon: 'download',
          action: 'download'
        }
        //{
        //  label: 'inquiry.menu.share-by-email',
        //  icon: 'mail',
        //  action: 'email'
        //},
        //{
        //  label: 'inquiry.menu.print',
        //  icon: 'printer',
        //  action: 'print'
        //}
      ]
    }
    return menu
  }

  isLoading = true
  isDeleting = false

  errorHandlerService = inject(ErrorHandlerKey)

  inquiry = {
    title: 'Placeholder name'
  } as Inquiry

  beforeMount () {
    this.loadInquiry()
  }

  checkReUploadMessage (inquiry: Inquiry) {
    this.showReUploadMessage = inquiry.files.some((file) => file.action === 'retake_photo')
  }

  loadInquiry () {
    const id = this.$route.params.id.toString()
    getInquiry(id)
      .then((response) => {
        this.inquiry = response.data
        this.isLoading = false
        this.loadImages(this.inquiry)
        this.loadConfirmation(this.inquiry)
        this.checkReUploadMessage(this.inquiry)
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  loadConfirmation (inquiry: Inquiry) {
    if (inquiry.confirmation) {
      getFileDownload(inquiry.confirmation)
        .then((response) => {
          this.confirmationType = response.data.mimeType
          this.confirmationContent = `data:${response.data.mimeType};base64, ${response.data.content}`
        })
        .catch((error) => {
          console.log('confirmation')
          this.errorHandlerService?.loadError(error.response.data)
        })
    }
  }

  previewConfirmation () {
    disableBodyScroll('confirmation-lightbox')
    this.confirmationLightboxIsVisible = true
  }

  loadImages (inquiry: Inquiry) {
    inquiry.files.forEach((file) => {
      getFileDownload(file.url)
        .then((response) => {
          const image = {
            'fileName': response.data.fileName,
            'imageContent': `data:${response.data.mimeType};base64, ${response.data.content}`
          }
          this.inquiryUploads.push(image)
          this.addImageToLightbox(response.data.mimeType, image.imageContent)
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
    })
  }

  addImageToLightbox (type: string, image: string) {
    if (type !== 'application/pdf') {
      this.lightboxImages.push(image)
    }
  }

  showUploadsLightbox (indexToOpen: number) {
    this.lightboxToOpenIndex = indexToOpen
    disableBodyScroll('uploads-lightbox')
    this.lightboxIsVisible = true
  }

  hideLightbox () {
    enableBodyScroll('uploads-lightbox')
    enableBodyScroll('confirmation-lightbox')
    this.lightboxIsVisible = false
    this.confirmationLightboxIsVisible = false
  }

  deleteInquiry () {
    this.isDeleting = true
    deleteInquiry(this.inquiry.id)
      .then(() => {
        this.updateInquiries()
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  inquiriesService = inject(InquiryServiceKey)
  updateInquiries () {
    Promise.all([
      this.inquiriesService?.loadInquiries('recent', 1, 20, true),
      this.inquiriesService?.loadInquiries('reimbursed', 1, 20, true),
      this.inquiriesService?.loadInquiries('rejected', 1, 20, true),
      this.inquiriesService?.loadInquiries('pending', 1, 20, true)
    ]).then(() => {
      this.$router.push({ name: 'Inquiries' })
    })
  }
}
