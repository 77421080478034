<template>
  <form :class="[isLoading && 'is-loading', errors && 'form has-errors']" ref="form">
    <h1 class="display-1 mb-l">{{ $t('new-inquiry.title') }}</h1>
    <div class="new-inquiry__grid">
      <div>
        <div class="new-inquiry__main">
          <div class="new-inquiry__form">
            <div class="mb-l">
              <InputLabel small>{{ $t('new-inquiry.category') }}</InputLabel>
              <InputSelect @click="openModal('inquiries-new-inquiry-category-select')" modal :placeholder="category" class="new-inquiry__category-select" />
              <Modal modalId="inquiries-new-inquiry-category-select">
                <template #title>{{ $t('new-inquiry.category-modal.title') }}</template>
                <div class="new-inquiry__modal__form" @change="setCategory">
                  <CategoryCheckbox :item="item" v-for="item in inquiriesService.categories" v-bind:key="item">
                    {{ item.title }}
                  </CategoryCheckbox>
                </div>
              </Modal>
            </div>
            <div class="mb-l">
              <InputLabel for="title" small>{{ $t('new-inquiry.optional-title') }}</InputLabel>
              <InputText id="title" v-model="newInquiryTitle" />
            </div>
          </div>
          <HelpText class="mb-l">{{ $t('new-inquiry.note') }}</HelpText>
        </div>
        <NewInquiryUploads @add-new-inquiry-upload="addNewInquiryUpload"
                           @remove-new-inquiry-upload="removeNewInquiryUpload"
                           @add-qr-file="addQrFile"
                           :newInquiryUploads="newInquiryUploads"
                           class="mb-l" />
        <div class="new-inquiry__main">
          <HelpToggle class="mb-l lg-up">
            <template #title>{{ $t('new-inquiry.help-label') }}</template>
            {{ $t('new-inquiry.help-text') }}
          </HelpToggle>
        </div>
      </div>
      <div>
        <div class="new-inquiry__aside mb-xl">
          <TripCancellationFormUploads v-if="hasTripCancellationCategory" />
          <CnsUploads v-else
                      @add-new-cns-upload="addNewCnsUpload"
                      @remove-new-cns-upload="removeNewCnsUpload"
                      @add-qr-file="addQrFile"
                      @add-previous-cns-uploads="addPreviousCnsUploads"
                      :newCnsUploads="newCnsUploads"
          />
        </div>
      </div>
      <div class="new-inquiry__main">
        <IconButton icon="credit-card" background="transparent" @click="toggleBankAccounts">
          {{ $t('new-inquiry.change-bank') }}
        </IconButton>
        <div v-if="bankAccountsIsVisible" class="new-inquiry__bank-accounts-grid">
          <InputRadio
            class="mr-l"
            group="accounts"
            :inputId="item.id"
            v-bind:key="item.id"
            :checked="item.id === newInquiryBankAccountId && ''"
            v-model="newInquiryBankAccountId"
            v-for="item in bankAccountsService.bankAccounts"
          >
            <div>
              <span class="d-b fw-700">{{ item.name }}</span>
              <span class="d-b fs-12">({{ item.iban }})</span>
            </div>
          </InputRadio>
        </div>
      </div>
    </div>
    <Separator></Separator>
    <div class="ta-center mb-l">
      <Button @click="submit($event)" type="submit">{{ $t('new-inquiry.submit') }}</Button>
    </div>
  </form>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import CnsUploads, { PreviousUploadTemplate } from '@/03-organisms/Cns-uploads.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputSelect from '@/01-atoms/Input-select.vue'
import HelpText from '@/02-molecules/Help-text.vue'
import Button from '@/01-atoms/Button.vue'
import Separator from '@/02-molecules/Separator.vue'
import HelpToggle from '@/02-molecules/Help-toggle.vue'
import Modal from '@/02-molecules/Modal.vue'
import CategoryCheckbox from '@/01-atoms/Category-checkbox.vue'
import eventHub from '@/event-hub'
import NewInquiryUploads from '@/03-organisms/New-inquiry-uploads.vue'
import TripCancellationFormUploads from '@/03-organisms/Trip-cancellation-form-uploads.vue'
import InputRadio from '@/01-atoms/Input-radio.vue'
import { inject } from 'vue'
import { BankAccountServiceKey, ErrorHandlerKey, InquiryServiceKey, MemberServiceKey } from '@/services/serviceTypes'
import { postInquiry } from '@/api/inquiries/inquiries-api'
import { PatchInquiry } from '@/services/inquiries/InquiryService'
import { getFileDownload } from '@/api/uploads/files-api'
import { DocumentFile } from '@/services/documents/DocumentService'

interface Inquiry {
  categoryId: string;
  title: string;
  files: string[];
  bankAccount: string;
  tripCancellation?: {
    doctorDocument: string;
    cancellationReason: string;
    memberId: string;
  };
}

export interface Upload {
  title: string;
  file: string;
  fileType: string;
  id: string;
}

@Options({
  name: 'New-inquiry',
  components: {
    IconButton,
    CnsUploads,
    InputLabel,
    InputText,
    InputSelect,
    HelpText,
    Button,
    Separator,
    HelpToggle,
    Modal,
    CategoryCheckbox,
    NewInquiryUploads,
    TripCancellationFormUploads,
    InputRadio
  }
})

export default class NewInquiry extends Vue {
  errors = false
  category = ''

  newInquiryCategoryId = ''
  newInquiryTitle = ''
  newInquiryBankAccountId = ''

  bankAccountsIsVisible = false

  isLoading = false

  beforeMount () {
    this.loadCategories()
    this.loadBanks()
    this.loadMemberBankAccount()
  }

  inquiriesService = inject(InquiryServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)
  loadCategories () {
    this.inquiriesService?.loadCategories()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  bankAccountsService = inject(BankAccountServiceKey)
  loadBanks () {
    this.bankAccountsService?.loadBankAccounts()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  get hasTripCancellationCategory () {
    let response = false
    if (this.inquiriesService?.categories.length) {
      const tripCancellationCategory = this.inquiriesService?.categories.find(category => category.uploadType === 'trip_cancellation')
      if (tripCancellationCategory && tripCancellationCategory.id === this.newInquiryCategoryId) {
        response = true
      }
    }
    return response
  }

  currentMemberService = inject(MemberServiceKey)
  loadMemberBankAccount () {
    this.currentMemberService?.loadCurrentMember()
      .then(() => {
        if (this.currentMemberService?.currentMember.bankAccounts.length) {
          this.newInquiryBankAccountId = this.currentMemberService?.currentMember.bankAccounts[0].id
        }
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  toggleBankAccounts () {
    this.bankAccountsIsVisible = !this.bankAccountsIsVisible
  }

  setCategory (ev: Event) {
    if (ev.target !== null) {
      this.category = ev.target['value']
      this.newInquiryCategoryId = ev.target['id']
      this.errors = false
    }
    eventHub.$emit('close-modal')
  }

  submit (ev: Event) {
    ev.preventDefault()
    if (this.formIsValid()) {
      this.createInquiry()
    } else {
      this.errors = true
    }
  }

  formIsValid () {
    return this.newInquiryCategoryId !== ''
  }

  createInquiry () {
    const newInquiry = {
      title: this.newInquiryTitle,
      bankAccount: this.newInquiryBankAccountId,
      categoryId: this.newInquiryCategoryId,
      files: this.createFilesIdList()
    }
    this.sendInquiry(newInquiry)
  }

  createFilesIdList () {
    const filesIds: Array<string> = []
    this.newInquiryUploads.forEach((inquiry) => {
      filesIds.push(inquiry.id)
    })
    this.newCnsUploads.forEach((inquiry) => {
      filesIds.push(inquiry.id)
    })
    return filesIds
  }

  sendInquiry (inquiry: PatchInquiry) {
    this.isLoading = true
    postInquiry(inquiry)
      .then((response) => {
        const id = response.data.id
        this.updateInquiries(id)
      })
  }

  updateInquiries (id: string) {
    Promise.all([
      this.inquiriesService?.loadInquiries('recent', 1, 20, true),
      this.inquiriesService?.loadInquiries('pending', 1, 20, true)
    ]).then(() => {
      this.forwardToInquiry(id)
    })
  }

  forwardToInquiry (id: string) {
    this.$router.push({ name: 'Inquiry', params: { id: id } })
  }

  invalidateForm () {
    this.errors = true
  }

  newInquiryUploads = [] as Array<Upload>

  addNewInquiryUpload (upload: Upload) {
    this.newInquiryUploads.push(upload)
  }

  removeNewInquiryUpload (id: string) {
    this.newInquiryUploads = this.newInquiryUploads.filter((item) => {
      return id !== item.id
    })
  }

  newCnsUploads = [] as Array<Upload>

  addPreviousCnsUploads (uploads: Array<PreviousUploadTemplate>) {
    uploads.forEach((upload) => {
      const item: Upload = {
        title: upload.itemId,
        file: upload.imageContent,
        fileType: 'cns',
        id: upload.itemId
      }
      this.newCnsUploads.push(item)
    })
  }

  addNewCnsUpload (upload: Upload) {
    this.newCnsUploads.push(upload)
  }

  removeNewCnsUpload (id: string) {
    this.newCnsUploads = this.newCnsUploads.filter((item) => {
      return id !== item.id
    })
  }

  addQrFile (file: any, type: 'default' | 'cns') {
    getFileDownload(file.url)
      .then((response) => {
        const image = {
          'title': response.data.fileName,
          'file': `data:${response.data.mimeType};base64, ${response.data.content}`,
          'fileType': type,
          'id': file.id
        }
        if (type === 'default') {
          this.newInquiryUploads.push(image)
        } else {
          this.newCnsUploads.push(image)
        }
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }
}
</script>
<style lang="scss" scoped>
.new-inquiry__grid {
  display: grid;
  grid-template-columns: auto 1fr;

  & > div:first-child {
    min-width: 600px;

    @include breakpoint(large down) {
      min-width: unset;
    }
  }

  @include breakpoint(large down) {
    grid-template-columns: 1fr;
  }
}

.new-inquiry__form {
  display: grid;
  grid-template-columns: 200px 1fr;
  max-width: 550px;

  & > div:first-child {
    margin-right: 30px;

    @include breakpoint(large down) {
      margin-right: 0;
    }
  }

  @include breakpoint(large down) {
    grid-template-columns: 1fr;
  }
}

.new-inquiry__main {
  max-width: 600px;
}

.new-inquiry__modal__form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  max-width: 400px;

  @include breakpoint(large down) {
    grid-template-columns: 1fr;
  }
}

.new-inquiry__aside {
  width: 280px;

  @include breakpoint(medium down) {
    margin-right: auto;
    margin-left: auto;
  }
}

.new-inquiry__bank-accounts-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include breakpoint(medium down) {
    grid-template-columns: 1fr;
  }
}

.form.has-errors {
  .new-inquiry__category-select {
    border-bottom: 1px solid $red;
  }
}
</style>
