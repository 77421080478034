
import { Options, Vue, prop } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  item = prop({
    type: Object,
    required: true,
    default: {
      id: 'dental_care',
      title: 'Dental Care',
      icon: 'eye',
      meta: ''
    }
  })
}

@Options({
  name: 'Category-checkbox',
  components: {
    Icon
  }
})

export default class CategoryCheckbox extends Vue.with(Props) {}
