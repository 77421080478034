<template>
  <div :class="[isLoading && 'is-loading']">
    <BackButton :to="{ name: 'Inquiry', params: { id: $route.params.id } }" />
    <h1 class="display-1 mb-l">{{ $t('edit-inquiry.title') }}</h1>
    <div class="new-inquiry__grid">
      <div>
        <div class="new-inquiry__main">
          <div class="new-inquiry__form">
            <div class="mb-l">
              <InputLabel small>{{ $t('new-inquiry.category') }}</InputLabel>
              <InputSelect @click="openModal('inquiries-new-inquiry-category-select')" modal :placeholder="categoryLabel" />
              <Modal modalId="inquiries-new-inquiry-category-select">
                <template #title>{{ $t('new-inquiry.category-modal.title') }}</template>
                <div class="new-inquiry__modal__form" @change="setCategory">
                  <CategoryCheckbox :item="item" v-for="item in inquiriesService.categories" v-bind:key="item">
                    {{ item.title }}
                  </CategoryCheckbox>
                </div>
              </Modal>
            </div>
            <div class="mb-l">
              <InputLabel for="title" small>{{ $t('new-inquiry.title-label') }}</InputLabel>
              <InputText id="title" v-model="inquiryTitle"></InputText>
            </div>
          </div>
        </div>
        <NewInquiryUploads @add-new-inquiry-upload="addNewInquiryUpload"
                           @remove-new-inquiry-upload="removeNewInquiryUpload"
                           :newInquiryUploads="inquiryUploads"
                           @add-qr-file="addQrFile"
                           class="mb-l" />
        <div class="new-inquiry__main">
          <HelpToggle class="mb-l lg-up">
            <template #title>{{ $t('new-inquiry.help-label') }}</template>
            {{ $t('new-inquiry.help-text') }}
          </HelpToggle>
        </div>
      </div>
      <div>
        <div class="new-inquiry__aside mb-xl">
          <CnsUploads
                      @add-new-cns-upload="addNewCnsUpload"
                      @remove-new-cns-upload="removeNewCnsUpload"
                      @add-qr-file="addQrFile"
                      :newCnsUploads="newCnsUploads"
          />
        </div>
      </div>
      <div class="new-inquiry__main">
        <IconButton icon="credit-card" background="transparent" @click="toggleBankAccounts">
          {{ $t('new-inquiry.change-bank') }}
        </IconButton>
        <div class="new-inquiry__bank-accounts-grid" v-if="bankAccountsIsVisible">
          <InputRadio
              class="mr-l"
              group="accounts"
              :inputId="item.id"
              v-bind:key="item.id"
              :checked="item.id === inquiryBankAccountId && ''"
              v-model="inquiryBankAccountId"
              v-for="item in bankAccountsService.bankAccounts"
          >
            <div>
              <span class="d-b fw-700">{{ item.name }}</span>
              <span class="d-b fs-12">({{ item.iban }})</span>
            </div>
          </InputRadio>
        </div>
      </div>
    </div>
    <Separator></Separator>
    <div class="ta-center mb-l">
      <Button @click="submit($event)" type="submit">{{ $t('global.save') }}</Button>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import BackButton from '@/02-molecules/Back-button.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import CnsUploads from '@/03-organisms/Cns-uploads.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputSelect from '@/01-atoms/Input-select.vue'
import HelpText from '@/02-molecules/Help-text.vue'
import Button from '@/01-atoms/Button.vue'
import Separator from '@/02-molecules/Separator.vue'
import HelpToggle from '@/02-molecules/Help-toggle.vue'
import Modal from '@/02-molecules/Modal.vue'
import CategoryCheckbox from '@/01-atoms/Category-checkbox.vue'
import eventHub from '@/event-hub'
import NewInquiryUploads from '@/03-organisms/New-inquiry-uploads.vue'
import TripCancellationFormUploads from '@/03-organisms/Trip-cancellation-form-uploads.vue'
import InputRadio from '@/01-atoms/Input-radio.vue'
import router from '@/router'
import { inject } from 'vue'
import { BankAccountServiceKey, ErrorHandlerKey, InquiryServiceKey } from '@/services/serviceTypes'
import { getInquiry, patchInquiry } from '@/api/inquiries/inquiries-api'
import { getFileDownload } from '@/api/uploads/files-api'
import { Inquiry, PatchInquiry } from '@/services/inquiries/InquiryService'
import { Upload } from '@/04-pages/02-Inquiries/Inquiries-new-inquiry.vue'

@Options({
  name: 'New-inquiry',
  components: {
    IconButton,
    CnsUploads,
    InputLabel,
    InputText,
    InputSelect,
    HelpText,
    Button,
    Separator,
    HelpToggle,
    Modal,
    CategoryCheckbox,
    NewInquiryUploads,
    TripCancellationFormUploads,
    InputRadio,
    BackButton
  }
})

export default class NewInquiry extends Vue {
  inquiryUploads: Array<Upload> = []

  inquiryTitle = ''
  inquiryBankAccountId = ''
  inquiryCategoryId = ''

  categoryLabel = ''

  bankAccountsIsVisible = false

  isLoading = true

  inquiry = {} as Inquiry

  beforeMount () {
    this.loadCategories()
    this.loadInquiry()
    this.loadBankAccounts()
  }

  inquiriesService = inject(InquiryServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)
  bankAccountsService = inject(BankAccountServiceKey)
  loadCategories () {
    this.inquiriesService?.loadCategories()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  loadBankAccounts () {
    this.bankAccountsService?.loadBankAccounts()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  loadInquiry () {
    const inquiryId = this.$route.params.id.toString()
    getInquiry(inquiryId)
      .then((response) => {
        this.inquiry = response.data
        this.isLoading = false
        this.setInquiry()
        this.loadImages(this.inquiry)
        this.categoryLabel = this.inquiry.category.title
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  setInquiry () {
    this.inquiryTitle = this.inquiry.title
    this.inquiryBankAccountId = this.inquiry.bankAccount.id
    this.inquiryCategoryId = this.inquiry.category.id
  }

  loadImages (inquiry: Inquiry) {
    inquiry.files.forEach((file) => {
      getFileDownload(file.url)
        .then((response) => {
          const image = {
            'title': response.data.fileName,
            'file': `data:${response.data.mimeType};base64, ${response.data.content}`,
            'fileType': file.fileType,
            'id': file.id
          }
          if (file.fileType === 'default') {
            this.inquiryUploads.push(image)
          } else {
            this.newCnsUploads.push(image)
          }
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
    })
  }

  toggleBankAccounts () {
    this.bankAccountsIsVisible = !this.bankAccountsIsVisible
  }

  setCategory (ev: Event) {
    if (ev.target !== null) {
      this.categoryLabel = ev.target['value']
      this.inquiryCategoryId = ev.target['id']
    }
    eventHub.$emit('close-modal')
  }

  submit (ev: Event) {
    this.isLoading = true
    ev.preventDefault()
    this.createPatchInquiry()
  }

  createPatchInquiry () {
    const patchInquiry = {
      title: this.inquiryTitle,
      bankAccount: this.inquiryBankAccountId,
      categoryId: this.inquiryCategoryId,
      files: this.createFilesIdList()
    }
    this.patchInquiry(patchInquiry)
  }

  createFilesIdList () {
    const filesIds: Array<string> = []
    this.inquiryUploads.forEach((inquiry) => {
      filesIds.push(inquiry.id)
    })
    this.newCnsUploads.forEach((inquiry) => {
      filesIds.push(inquiry.id)
    })
    return filesIds
  }

  patchInquiry (inquiry: PatchInquiry) {
    const inquiryId = this.$route.params.id.toString()
    patchInquiry(inquiryId, inquiry)
      .then(() => {
        router.push({ name: 'Inquiry', params: { id: inquiryId } })
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  addNewInquiryUpload (upload: Upload) {
    this.inquiryUploads.push(upload)
  }

  removeNewInquiryUpload (id: string) {
    this.inquiryUploads = this.inquiryUploads.filter((item) => {
      return id !== item.id
    })
  }

  newCnsUploads = [] as Array<Upload>

  addNewCnsUpload (upload: Upload) {
    this.newCnsUploads.push(upload)
  }

  removeNewCnsUpload (id: string) {
    this.newCnsUploads = this.newCnsUploads.filter((item) => {
      return id !== item.id
    })
  }

  addQrFile (file: any, type: 'default' | 'cns') {
    getFileDownload(file.url)
      .then((response) => {
        const image = {
          'title': response.data.fileName,
          'file': `data:${response.data.mimeType};base64, ${response.data.content}`,
          'fileType': type,
          'id': file.id
        }
        if (type === 'default') {
          this.inquiryUploads.push(image)
        } else {
          this.newCnsUploads.push(image)
        }
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }
}
</script>
<style lang="scss" scoped>
.new-inquiry__grid {
  display: grid;
  grid-template-columns: auto 1fr;

  & > div:first-child {
    min-width: 600px;

    @include breakpoint(large down) {
      min-width: unset;
    }
  }

  @include breakpoint(large down) {
    grid-template-columns: 1fr;
  }
}

.new-inquiry__form {
  display: grid;
  grid-template-columns: 200px 1fr;
  max-width: 550px;

  & > div:first-child {
    margin-right: 30px;

    @include breakpoint(large down) {
      margin-right: 0;
    }
  }

  @include breakpoint(large down) {
    grid-template-columns: 1fr;
  }
}

.new-inquiry__main {
  max-width: 600px;
}

.new-inquiry__modal__form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  max-width: 400px;

  @include breakpoint(large down) {
    grid-template-columns: 1fr;
  }
}

.new-inquiry__aside {
  width: 280px;

  @include breakpoint(medium down) {
    margin-right: auto;
    margin-left: auto;
  }
}

.new-inquiry__bank-accounts-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include breakpoint(medium down) {
    grid-template-columns: 1fr;
  }
}
</style>
