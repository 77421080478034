<template>
  <BackButton :to="{name: 'Inquiries'}" fallback />
  <div :class="[isLoading && 'is-loading', isDeleting && 'is-deleting']">
    <div class="mb-l">
      <div class="inquiries-inquiry__header d-f">
        <Icon v-if="inquiry.category" class="mr-s" :icon="inquiry.category.icon" background="blue-dark"></Icon>
        <h1 class="display-1 placeholder-animation">{{ inquiry.title }}</h1>
        <MoreMenu :menu="inquiryMenu()" @menu-action="menuAction" class="inquiries-inquiry__more-menu" />
      </div>
    </div>
    <div class="mb-m d-f">
      <span class="placeholder-animation">
        <span class="display-3 mr-m">{{ $t('inquiry.request-status') }}:</span>
        <Tag v-if="inquiry.status" :variant="inquiry.status" />
      </span>
    </div>
    <div class="mb-m d-f" >
      <span class="placeholder-animation">
        <span class="display-3 mr-m">{{ $t('inquiry.reimbursement-status') }}:</span>
        <Tag v-if="inquiry.treatmentStatus" :variant="inquiry.treatmentStatus" />
      </span>
    </div>
    <HelpText class="mb-l xm-down" variant="alert">{{ $t('inquiry.re-upload-image-error') }}</HelpText>
    <div class="inquiries-inquiry__grid placeholder-animation">
      <div>
        <p class="fw-700 fs-16">{{ inquiry.uploadBy }}</p>
        <table class="inquiries-inquiry__table fs-16">
          <tr>
            <th>{{ $t('inquiry.submission-date') }}</th>
            <th v-if="inquiry.repliedAt">{{ $t('inquiry.response-date' )}}</th>
          </tr>
          <tr>
            <td>{{ formatDate(inquiry.uploadedAt) }}</td>
            <td v-if="inquiry.repliedAt">{{ formatDate(inquiry.repliedAt) }}</td>
          </tr>
        </table>
        <Separator />
        <div v-if="inquiry.bankAccount">
          <div class="tt-uppercase d-f mb-s">
            <Icon class="mr-s" icon="credit-card"></Icon><span>{{ $t('inquiry.bank-label') }}</span>
          </div>
          <span class="fw-700 fs-16">{{ inquiry.bankAccount.name }}</span>
          <Separator />
        </div>
        <HelpText v-if="showReUploadMessage" class="mb-l lg-up" variant="alert">{{ $t('inquiry.re-upload-image-error') }}</HelpText>
        <div v-for="item in inquiry.files" v-bind:key="item.id">
          <HelpText class="mb-l lg-up" variant="alert" v-if="item.feedback">{{ item.feedback }}</HelpText>
        </div>
        <table class="inquiries-inquiry__summary fs-16">
          <tr v-if="inquiry.uploadTotalRefund">
            <th>{{ $t('inquiry.total-refund') }}</th>
            <td class="fs-24">{{ convertAmountToEuro(inquiry.uploadTotalRefund) }}</td>
          </tr>
          <tr v-if="confirmationContent">
            <th>{{ $t('inquiry.confirmation') }}</th>
            <td class="inquiries-inquiry__summary__actions">
              <IconButton icon="eye" background="transparent" class="mr-s" round @click="previewConfirmation" v-if="confirmationType !== 'application/pdf'" />
              <IconButton icon="download" background="transparent" round :href="confirmationContent" :download="inquiry.title" ref="confirmationButton" />
            </td>
            <vue-easy-lightbox
              v-if="confirmationType !== 'application/pdf'"
              escDisabled
              moveDisabled
              :imgs="confirmationContent"
              ref="confirmation-lightbox"
              @hide="hideLightbox"
              :visible="confirmationLightboxIsVisible"
            ></vue-easy-lightbox>
          </tr>
        </table>
        <!--      will probably not be needed anymore-->
        <!--      <div v-if="inquiry.splittedInquiries.length > 1">-->
        <!--        <Separator />-->
        <!--        <h2 class="display-3 blue-dark mr-m">{{ $t('inquiry.split-requests') }}:</h2>-->
        <!--        <ul class="mb-l">-->
        <!--          <li class="inquiries-inquiry__split-item" v-for="split in inquiry.splittedInquiries" v-bind:key="split.id">-->
        <!--            <router-link :to="{ name: 'Inquiry', params: { id: split.inquiryId } }" class="inquiries-inquiry__split-item__link">-->
        <!--              <Icon round class="mr-m" background="blue-dark" :icon="split.category.icon"></Icon>-->
        <!--              <div>-->
        <!--                <span class="fs-16 fw-700">{{ split.category.title }}</span>-->
        <!--                <span class="d-b grey-dark">{{ split.category.id }}</span>-->
        <!--              </div>-->
        <!--            </router-link>-->
        <!--          </li>-->
        <!--        </ul>-->
        <!--        <HelpText variant="success">{{ $t('inquiry.split-note') }}</HelpText>-->
        <!--      </div>-->
      </div>
      <div class="inquiries-inquiry__upload-grid mb-l">
        <Inquiry-upload
          :file="inquiry.files[index]"
          :item="item"
          :inquiryId="inquiry.id"
          v-bind:key="item.title"
          v-for="(item, index) in inquiryUploads"
          @show-uploads-lightbox="showUploadsLightbox(index)"
        />
        <vue-easy-lightbox
          escDisabled
          moveDisabled
          :imgs="lightboxImages"
          ref="uploads-lightbox"
          @hide="hideLightbox"
          :visible="lightboxIsVisible"
          :index="lightboxToOpenIndex"
        ></vue-easy-lightbox>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Separator from '@/02-molecules/Separator.vue'
import Icon from '@/01-atoms/Icon.vue'
import HelpText from '@/02-molecules/Help-text.vue'
import Tag from '@/01-atoms/Tag.vue'
import InquiryUpload from '@/02-molecules/Inquiry-upload.vue'
import MoreMenu from '@/02-molecules/More-menu.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { getInquiry, deleteInquiry } from '@/api/inquiries/inquiries-api'
import { inject } from 'vue'
import { ErrorHandlerKey, InquiryServiceKey } from '@/services/serviceTypes'
import { Image, Inquiry } from '@/services/inquiries/InquiryService'
import { getFileDownload } from '@/api/uploads/files-api'
import sanitizeDownloadTitle from '@/helpers/sanitize-download-title'

@Options({
  name: 'Inquiry',
  components: {
    IconButton,
    Separator,
    Icon,
    HelpText,
    Tag,
    InquiryUpload,
    MoreMenu,
    BackButton
  }
})

export default class InquiryOverview extends Vue {
  inquiryUploads: Array<Image> = []
  lightboxImages: Array<string> = []
  lightboxIsVisible = false
  lightboxToOpenIndex = 0

  confirmationContent = ''
  confirmationType = ''
  confirmationLightboxIsVisible = false

  showReUploadMessage = false

  menu = [
    //{
    //  label: 'inquiry.menu.add',
    //  icon: 'camera'
    //},
    {
      label: 'inquiry.menu.edit',
      icon: 'edit-2',
      action: 'edit'
    },
    {
      label: 'inquiry.menu.delete',
      icon: 'trash',
      class: 'red',
      action: 'delete'
    }
  ]

  menuAction (action: string) {
    switch (action) {
      case 'delete':
        this.deleteInquiry()
        break
      case 'edit':
        this.$router.push({ name: 'Edit inquiry', params: { id: this.inquiry.id } })
        break
      case 'download': {
        const hiddenElement = document.createElement('a')
        hiddenElement.setAttribute('download', sanitizeDownloadTitle(this.inquiry.title))
        hiddenElement.setAttribute('href', this.confirmationContent)
        hiddenElement.click()
        break
      }
    }
  }

  inquiryMenu () {
    let menu = []
    if (this.inquiry.status === 'pending') {
      menu = [
        //{
        //  label: 'inquiry.menu.add',
        //  icon: 'camera'
        //},
        {
          label: 'inquiry.menu.edit',
          icon: 'edit-2',
          action: 'edit'
        },
        {
          label: 'inquiry.menu.delete',
          icon: 'trash',
          class: 'red',
          action: 'delete'
        }
      ]
    } else {
      menu = [
        {
          label: 'inquiry.menu.save-as-pdf',
          icon: 'download',
          action: 'download'
        }
        //{
        //  label: 'inquiry.menu.share-by-email',
        //  icon: 'mail',
        //  action: 'email'
        //},
        //{
        //  label: 'inquiry.menu.print',
        //  icon: 'printer',
        //  action: 'print'
        //}
      ]
    }
    return menu
  }

  isLoading = true
  isDeleting = false

  errorHandlerService = inject(ErrorHandlerKey)

  inquiry = {
    title: 'Placeholder name'
  } as Inquiry

  beforeMount () {
    this.loadInquiry()
  }

  checkReUploadMessage (inquiry: Inquiry) {
    this.showReUploadMessage = inquiry.files.some((file) => file.action === 'retake_photo')
  }

  loadInquiry () {
    const id = this.$route.params.id.toString()
    getInquiry(id)
      .then((response) => {
        this.inquiry = response.data
        this.isLoading = false
        this.loadImages(this.inquiry)
        this.loadConfirmation(this.inquiry)
        this.checkReUploadMessage(this.inquiry)
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  loadConfirmation (inquiry: Inquiry) {
    if (inquiry.confirmation) {
      getFileDownload(inquiry.confirmation)
        .then((response) => {
          this.confirmationType = response.data.mimeType
          this.confirmationContent = `data:${response.data.mimeType};base64, ${response.data.content}`
        })
        .catch((error) => {
          console.log('confirmation')
          this.errorHandlerService?.loadError(error.response.data)
        })
    }
  }

  previewConfirmation () {
    disableBodyScroll('confirmation-lightbox')
    this.confirmationLightboxIsVisible = true
  }

  loadImages (inquiry: Inquiry) {
    inquiry.files.forEach((file) => {
      getFileDownload(file.url)
        .then((response) => {
          const image = {
            'fileName': response.data.fileName,
            'imageContent': `data:${response.data.mimeType};base64, ${response.data.content}`
          }
          this.inquiryUploads.push(image)
          this.addImageToLightbox(response.data.mimeType, image.imageContent)
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
    })
  }

  addImageToLightbox (type: string, image: string) {
    if (type !== 'application/pdf') {
      this.lightboxImages.push(image)
    }
  }

  showUploadsLightbox (indexToOpen: number) {
    this.lightboxToOpenIndex = indexToOpen
    disableBodyScroll('uploads-lightbox')
    this.lightboxIsVisible = true
  }

  hideLightbox () {
    enableBodyScroll('uploads-lightbox')
    enableBodyScroll('confirmation-lightbox')
    this.lightboxIsVisible = false
    this.confirmationLightboxIsVisible = false
  }

  deleteInquiry () {
    this.isDeleting = true
    deleteInquiry(this.inquiry.id)
      .then(() => {
        this.updateInquiries()
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  inquiriesService = inject(InquiryServiceKey)
  updateInquiries () {
    Promise.all([
      this.inquiriesService?.loadInquiries('recent', 1, 20, true),
      this.inquiriesService?.loadInquiries('reimbursed', 1, 20, true),
      this.inquiriesService?.loadInquiries('rejected', 1, 20, true),
      this.inquiriesService?.loadInquiries('pending', 1, 20, true)
    ]).then(() => {
      this.$router.push({ name: 'Inquiries' })
    })
  }
}
</script>
<style lang="scss" scoped>
.inquiries-inquiry__table,
.inquiries-inquiry__summary {
  color: $blue-dark;
  text-align: left;
  width: 100%;

  th {
    @include brandon-grotesque;
    font-size: 12px;
    text-transform: uppercase;
    padding: 5px 0;
  }

  td {
    @include brandon-grotesque-bold;
    padding: 5px 0;
  }
}

.inquiries-inquiry__summary {
  td {
    text-align: right;
  }
}

.inquiries-inquiry__summary__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.inquiries-inquiry__grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: $xlarge;
  align-items: flex-start;

  @include breakpoint(xmedium down) {
    display: flex;
    flex-direction: column-reverse;
    align-items: unset;
  }
}

.inquiries-inquiry__upload-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, min(calc(50% - (#{$medium} / 2)), 260px));
  row-gap: $xmedium;
  column-gap: $medium;
}

.inquiries-inquiry__header {
  position: relative;
  padding-right: 50px;
}

.inquiries-inquiry__more-menu {
  position: absolute;
  top: calc(50% - 23px);
  right: 0;
}

.inquiries-inquiry__split-item {
  overflow: hidden;
  position: relative;

  &::after {
    content: "";
    background-color: $blue-dark-10;
    height: 1px;
    width: 100%;
    position: absolute;
    left: 35px;
    bottom: 0;
  }

  &:first-child::before {
    content: "";
    background-color: $blue-dark-10;
    height: 1px;
    width: 100%;
    position: absolute;
    left: 35px;
    top: 0;
  }
}

.inquiries-inquiry__split-item__link {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  padding: 12px 0;
  position: relative;
}

.is-deleting {
  opacity: 0.5;
  pointer-events: none;
}
</style>
