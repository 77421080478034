
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import CnsUploads, { PreviousUploadTemplate } from '@/03-organisms/Cns-uploads.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputSelect from '@/01-atoms/Input-select.vue'
import HelpText from '@/02-molecules/Help-text.vue'
import Button from '@/01-atoms/Button.vue'
import Separator from '@/02-molecules/Separator.vue'
import HelpToggle from '@/02-molecules/Help-toggle.vue'
import Modal from '@/02-molecules/Modal.vue'
import CategoryCheckbox from '@/01-atoms/Category-checkbox.vue'
import eventHub from '@/event-hub'
import NewInquiryUploads from '@/03-organisms/New-inquiry-uploads.vue'
import TripCancellationFormUploads from '@/03-organisms/Trip-cancellation-form-uploads.vue'
import InputRadio from '@/01-atoms/Input-radio.vue'
import { inject } from 'vue'
import { BankAccountServiceKey, ErrorHandlerKey, InquiryServiceKey, MemberServiceKey } from '@/services/serviceTypes'
import { postInquiry } from '@/api/inquiries/inquiries-api'
import { PatchInquiry } from '@/services/inquiries/InquiryService'
import { getFileDownload } from '@/api/uploads/files-api'
import { DocumentFile } from '@/services/documents/DocumentService'

interface Inquiry {
  categoryId: string;
  title: string;
  files: string[];
  bankAccount: string;
  tripCancellation?: {
    doctorDocument: string;
    cancellationReason: string;
    memberId: string;
  };
}

export interface Upload {
  title: string;
  file: string;
  fileType: string;
  id: string;
}

@Options({
  name: 'New-inquiry',
  components: {
    IconButton,
    CnsUploads,
    InputLabel,
    InputText,
    InputSelect,
    HelpText,
    Button,
    Separator,
    HelpToggle,
    Modal,
    CategoryCheckbox,
    NewInquiryUploads,
    TripCancellationFormUploads,
    InputRadio
  }
})

export default class NewInquiry extends Vue {
  errors = false
  category = ''

  newInquiryCategoryId = ''
  newInquiryTitle = ''
  newInquiryBankAccountId = ''

  bankAccountsIsVisible = false

  isLoading = false

  beforeMount () {
    this.loadCategories()
    this.loadBanks()
    this.loadMemberBankAccount()
  }

  inquiriesService = inject(InquiryServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)
  loadCategories () {
    this.inquiriesService?.loadCategories()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  bankAccountsService = inject(BankAccountServiceKey)
  loadBanks () {
    this.bankAccountsService?.loadBankAccounts()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  get hasTripCancellationCategory () {
    let response = false
    if (this.inquiriesService?.categories.length) {
      const tripCancellationCategory = this.inquiriesService?.categories.find(category => category.uploadType === 'trip_cancellation')
      if (tripCancellationCategory && tripCancellationCategory.id === this.newInquiryCategoryId) {
        response = true
      }
    }
    return response
  }

  currentMemberService = inject(MemberServiceKey)
  loadMemberBankAccount () {
    this.currentMemberService?.loadCurrentMember()
      .then(() => {
        if (this.currentMemberService?.currentMember.bankAccounts.length) {
          this.newInquiryBankAccountId = this.currentMemberService?.currentMember.bankAccounts[0].id
        }
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  toggleBankAccounts () {
    this.bankAccountsIsVisible = !this.bankAccountsIsVisible
  }

  setCategory (ev: Event) {
    if (ev.target !== null) {
      this.category = ev.target['value']
      this.newInquiryCategoryId = ev.target['id']
      this.errors = false
    }
    eventHub.$emit('close-modal')
  }

  submit (ev: Event) {
    ev.preventDefault()
    if (this.formIsValid()) {
      this.createInquiry()
    } else {
      this.errors = true
    }
  }

  formIsValid () {
    return this.newInquiryCategoryId !== ''
  }

  createInquiry () {
    const newInquiry = {
      title: this.newInquiryTitle,
      bankAccount: this.newInquiryBankAccountId,
      categoryId: this.newInquiryCategoryId,
      files: this.createFilesIdList()
    }
    this.sendInquiry(newInquiry)
  }

  createFilesIdList () {
    const filesIds: Array<string> = []
    this.newInquiryUploads.forEach((inquiry) => {
      filesIds.push(inquiry.id)
    })
    this.newCnsUploads.forEach((inquiry) => {
      filesIds.push(inquiry.id)
    })
    return filesIds
  }

  sendInquiry (inquiry: PatchInquiry) {
    this.isLoading = true
    postInquiry(inquiry)
      .then((response) => {
        const id = response.data.id
        this.updateInquiries(id)
      })
  }

  updateInquiries (id: string) {
    Promise.all([
      this.inquiriesService?.loadInquiries('recent', 1, 20, true),
      this.inquiriesService?.loadInquiries('pending', 1, 20, true)
    ]).then(() => {
      this.forwardToInquiry(id)
    })
  }

  forwardToInquiry (id: string) {
    this.$router.push({ name: 'Inquiry', params: { id: id } })
  }

  invalidateForm () {
    this.errors = true
  }

  newInquiryUploads = [] as Array<Upload>

  addNewInquiryUpload (upload: Upload) {
    this.newInquiryUploads.push(upload)
  }

  removeNewInquiryUpload (id: string) {
    this.newInquiryUploads = this.newInquiryUploads.filter((item) => {
      return id !== item.id
    })
  }

  newCnsUploads = [] as Array<Upload>

  addPreviousCnsUploads (uploads: Array<PreviousUploadTemplate>) {
    uploads.forEach((upload) => {
      const item: Upload = {
        title: upload.itemId,
        file: upload.imageContent,
        fileType: 'cns',
        id: upload.itemId
      }
      this.newCnsUploads.push(item)
    })
  }

  addNewCnsUpload (upload: Upload) {
    this.newCnsUploads.push(upload)
  }

  removeNewCnsUpload (id: string) {
    this.newCnsUploads = this.newCnsUploads.filter((item) => {
      return id !== item.id
    })
  }

  addQrFile (file: any, type: 'default' | 'cns') {
    getFileDownload(file.url)
      .then((response) => {
        const image = {
          'title': response.data.fileName,
          'file': `data:${response.data.mimeType};base64, ${response.data.content}`,
          'fileType': type,
          'id': file.id
        }
        if (type === 'default') {
          this.newInquiryUploads.push(image)
        } else {
          this.newCnsUploads.push(image)
        }
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }
}
