<template>
  <HeaderSearch>{{ $t('inquiries.title') }}</HeaderSearch>
  <InquiriesPanel />
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import HeaderSearch from '@/03-organisms/Header-search.vue'
import InquiriesPanel from '@/03-organisms/Inquiries-panel.vue'

@Options({
  name: 'Inquiries',
  components: {
    InquiriesPanel,
    HeaderSearch
  }
})
export default class InquiryService extends Vue {
}
</script>
