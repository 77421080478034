
import { Options, Vue } from 'vue-class-component'
import BackButton from '@/02-molecules/Back-button.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import CnsUploads from '@/03-organisms/Cns-uploads.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputSelect from '@/01-atoms/Input-select.vue'
import HelpText from '@/02-molecules/Help-text.vue'
import Button from '@/01-atoms/Button.vue'
import Separator from '@/02-molecules/Separator.vue'
import HelpToggle from '@/02-molecules/Help-toggle.vue'
import Modal from '@/02-molecules/Modal.vue'
import CategoryCheckbox from '@/01-atoms/Category-checkbox.vue'
import eventHub from '@/event-hub'
import NewInquiryUploads from '@/03-organisms/New-inquiry-uploads.vue'
import TripCancellationFormUploads from '@/03-organisms/Trip-cancellation-form-uploads.vue'
import InputRadio from '@/01-atoms/Input-radio.vue'
import router from '@/router'
import { inject } from 'vue'
import { BankAccountServiceKey, ErrorHandlerKey, InquiryServiceKey } from '@/services/serviceTypes'
import { getInquiry, patchInquiry } from '@/api/inquiries/inquiries-api'
import { getFileDownload } from '@/api/uploads/files-api'
import { Inquiry, PatchInquiry } from '@/services/inquiries/InquiryService'
import { Upload } from '@/04-pages/02-Inquiries/Inquiries-new-inquiry.vue'

@Options({
  name: 'New-inquiry',
  components: {
    IconButton,
    CnsUploads,
    InputLabel,
    InputText,
    InputSelect,
    HelpText,
    Button,
    Separator,
    HelpToggle,
    Modal,
    CategoryCheckbox,
    NewInquiryUploads,
    TripCancellationFormUploads,
    InputRadio,
    BackButton
  }
})

export default class NewInquiry extends Vue {
  inquiryUploads: Array<Upload> = []

  inquiryTitle = ''
  inquiryBankAccountId = ''
  inquiryCategoryId = ''

  categoryLabel = ''

  bankAccountsIsVisible = false

  isLoading = true

  inquiry = {} as Inquiry

  beforeMount () {
    this.loadCategories()
    this.loadInquiry()
    this.loadBankAccounts()
  }

  inquiriesService = inject(InquiryServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)
  bankAccountsService = inject(BankAccountServiceKey)
  loadCategories () {
    this.inquiriesService?.loadCategories()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  loadBankAccounts () {
    this.bankAccountsService?.loadBankAccounts()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  loadInquiry () {
    const inquiryId = this.$route.params.id.toString()
    getInquiry(inquiryId)
      .then((response) => {
        this.inquiry = response.data
        this.isLoading = false
        this.setInquiry()
        this.loadImages(this.inquiry)
        this.categoryLabel = this.inquiry.category.title
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  setInquiry () {
    this.inquiryTitle = this.inquiry.title
    this.inquiryBankAccountId = this.inquiry.bankAccount.id
    this.inquiryCategoryId = this.inquiry.category.id
  }

  loadImages (inquiry: Inquiry) {
    inquiry.files.forEach((file) => {
      getFileDownload(file.url)
        .then((response) => {
          const image = {
            'title': response.data.fileName,
            'file': `data:${response.data.mimeType};base64, ${response.data.content}`,
            'fileType': file.fileType,
            'id': file.id
          }
          if (file.fileType === 'default') {
            this.inquiryUploads.push(image)
          } else {
            this.newCnsUploads.push(image)
          }
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
    })
  }

  toggleBankAccounts () {
    this.bankAccountsIsVisible = !this.bankAccountsIsVisible
  }

  setCategory (ev: Event) {
    if (ev.target !== null) {
      this.categoryLabel = ev.target['value']
      this.inquiryCategoryId = ev.target['id']
    }
    eventHub.$emit('close-modal')
  }

  submit (ev: Event) {
    this.isLoading = true
    ev.preventDefault()
    this.createPatchInquiry()
  }

  createPatchInquiry () {
    const patchInquiry = {
      title: this.inquiryTitle,
      bankAccount: this.inquiryBankAccountId,
      categoryId: this.inquiryCategoryId,
      files: this.createFilesIdList()
    }
    this.patchInquiry(patchInquiry)
  }

  createFilesIdList () {
    const filesIds: Array<string> = []
    this.inquiryUploads.forEach((inquiry) => {
      filesIds.push(inquiry.id)
    })
    this.newCnsUploads.forEach((inquiry) => {
      filesIds.push(inquiry.id)
    })
    return filesIds
  }

  patchInquiry (inquiry: PatchInquiry) {
    const inquiryId = this.$route.params.id.toString()
    patchInquiry(inquiryId, inquiry)
      .then(() => {
        router.push({ name: 'Inquiry', params: { id: inquiryId } })
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  addNewInquiryUpload (upload: Upload) {
    this.inquiryUploads.push(upload)
  }

  removeNewInquiryUpload (id: string) {
    this.inquiryUploads = this.inquiryUploads.filter((item) => {
      return id !== item.id
    })
  }

  newCnsUploads = [] as Array<Upload>

  addNewCnsUpload (upload: Upload) {
    this.newCnsUploads.push(upload)
  }

  removeNewCnsUpload (id: string) {
    this.newCnsUploads = this.newCnsUploads.filter((item) => {
      return id !== item.id
    })
  }

  addQrFile (file: any, type: 'default' | 'cns') {
    getFileDownload(file.url)
      .then((response) => {
        const image = {
          'title': response.data.fileName,
          'file': `data:${response.data.mimeType};base64, ${response.data.content}`,
          'fileType': type,
          'id': file.id
        }
        if (type === 'default') {
          this.inquiryUploads.push(image)
        } else {
          this.newCnsUploads.push(image)
        }
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }
}
